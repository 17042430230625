<template>
  <FocusTrap>
    <div>
    <!-- Basic view -->
    <div class="card" id="tourplan_card">
      <div class="card-header">
        <h5 class="card-title">Tour Plan</h5>
      </div>

      <div class="card-body">
        <div id='calendar'></div>
      </div>
    </div>
    <!-- /basic view -->

    <!-- /DayPlan view -->
    <div id="modal_dayplan" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Day Plan
              <span class="text-muted d-block">{{displayWorkingDate()}}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>

          <form action="#">
            <div class="modal-body">
              <div class="form-group">

                <div class="mb-3">
                  <h6 class="mb-0 font-weight-semibold">
                    1st Session
                  </h6>
<!--                  <span class="text-muted d-block">1st Session</span>-->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <label>Work Type</label>
                    <select id="cmbWorktype1" ref="el_worktype1" class="form-control" v-model="tourplanday.session1_worktype" @change="worktype1_changed">
                      <option value="FieldWork" selected>Field Work</option>
                      <option value="Meeting">Meeting</option>
                      <option value="CycleMeeting">Cycle Meeting</option>
                      <option value="Camp">Camp</option>
                      <option value="Deskwork">Deskwork</option>
                      <option value="Leave">Leave</option>
                    </select>
                  </div>

                  <div class="col-sm-6" >
                    <label>Areas</label>
                    <select id="cmbSession1Area" class="form-control" multiple="multiple"  >
                      <option v-for="area in areas" v-bind:value="area.id">
                        {{ area.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="py-3"></div>
                <div class="mb-3">
                  <h6 class="mb-0 font-weight-semibold">
                    2nd Session
                  </h6>
<!--                  <span class="text-muted d-block">2nd Session</span>-->
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <label>Work Type</label>
                    <select id="cmbWorktype2" ref="el_worktype2" class="form-control" v-model="tourplanday.session2_worktype" @change="worktype2_changed">
                      <option value="FieldWork" selected>Field Work</option>
                      <option value="Meeting">Meeting</option>
                      <option value="CycleMeeting">Cycle Meeting</option>
                      <option value="Camp">Camp</option>
                      <option value="Deskwork">Deskwork</option>
                      <option value="Leave">Leave</option>
                    </select>
                  </div>

                  <div class="col-sm-6">
                    <label>Areas</label>
                    <select id="cmbSession2Area" class="form-control multiselect" multiple="multiple">
                      <option v-for="area in areas" v-bind:value="area.id">
                        {{ area.name }}
                      </option>
                    </select>
                  </div>
                </div>


              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-outline-success" @click="perform_day_save">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /DayPlan view -->
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'TourPlanForm',
  store,
  data() {
    return {
      calendar : null,
      dayplan_date : '2000-01-01',
      session1_worktype : 'FieldWork',
      is_session1_area : false,
      areas : [],
      tourplan : JSON.parse('{"id":0,"month":"2000-00-01","type":0,"status":"Active","fieldpeople_id":2,"accepted_by_id":0,"accepted_by":{},"fieldpeople":{},"list":[]}'),
      tourplanday :  JSON.parse( '{"id":0,"master_id":0,"work_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","jointworker":{},"areas1":[],"areas2":[]}'),
    }
  },
  component: {
  },
  props: {

  },
  beforeMount () {

  },
  created () {
    const self = this;
    // self.areas = self.$store.state.user.areas;
    self.$store.state.user.areas.forEach((a)=>{
      let localArea = {"id":2069,"name":"Alandurai","type":2,"parent_id":1641,"parent_name":"CBE 1"};
      localArea =  {"id": 0, "master_id": 0, "detail_id": 0, "session_id": 1, "area_id": a.id, "work_date": "2000-00-01", "name": a.name};
      self.areas.push(localArea);
    });
    self.areas = self.$store.state.user.areas;
  },
  mounted () {
    const self = this;

    $('#cmbSession1Area').multiselect();

    $('#cmbSession2Area').multiselect();

    $('#modal_dayplan').on('shown.bs.modal', function (e) {
      self.$refs.el_worktype1.focus();
      $('#cmbWorktype1').focus();
    })

    // document.getElementsByClassName('fc-toolbar-chunk').remove();
    var calendarEl = document.getElementById('calendar');
    self.$data.calendar = new FullCalendar.Calendar(calendarEl, {
      headerToolbar: {
        start: 'title', // will normally be on the left
        center: '', // no center section
        end: 'prev,next', // will normally be on the right
      },
      editable: true,
      selectable: true,
      initialView: 'dayGridMonth',
      height: 'auto',
      initialDate: new Date(),
      dateClick: (info)=>{ self.showDayPlanDialog(info) },
      datesSet: function (evt) {
        try {
          let midDate = moment((evt.start.getTime() + evt.end.getTime()) / 2);
          self.calendarMonth_changed(midDate.month() + 1, midDate.year());
        }catch (e) {
          alert(e);
        }
      },
      events :[],
      eventClick: function(event, element) {
        self.on_event_click(event, element);
      },
    });
    self.$data.calendar.render();
  },
  computed:{
  },
  methods: {
    closeWindow(){
      this.$emit("tourplan_window_closed");
    },
    displayWorkingDate(){
      try {
        const self = this;
        return self.$data.dayplan_date.format('dddd MMMM Do YYYY');
      }catch (e) {
        return  'Error';
      }
    },
    calendarMonth_changed(month, year) {
      // console.log(month + " - " + year);
      // alert(year + "-" + ('00' + month).slice(-2)  + "-01");
      this.fetch_month_data(month, year);
    },
    showDayPlanDialog(day) {
      const self = this;
      let date = moment(day.dateStr);
      // self.day_plan_date = date.format('MMMM Do YYYY');
      self.dayplan_date = date;//.format('YYYY-MM-DD');

      self.fetch_dayplan();
      $('#modal_dayplan').modal('show');

    },
    worktype1_changed(){
      const self = this;

      try {
        let elArea1 = document.getElementById("cmbSession1Area");
        // elArea1.setAttribute('className', 'disabled');
        elArea1.classList.add('disabled');
      }catch (e) {
        alert(e);
      }

      // alert(self.session1_worktype !== 'fieldwork')
      // document.getElementById("cmbSession1Area").disabled = (self.session1_worktype !== 'fieldwork');
      // // self.is_session1_area = (self.session1_worktype !== 'fieldwork');
      // alert(document.getElementById("cmbSession1Area").disabled);

      let $selectedOptions = $('#cmbSession1Area').find('option:selected');
      $selectedOptions.each((idx, val) => {
        alert(val.value);
      });
    },
    worktype2_changed(){},
    loadAreas () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#tourplan_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.areas = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/areas`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data) ){
            self.$data.areas = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#tourplan_card').unblock();
      });

    },
    fetch_dayplan(){
      const self = this;

      const requestOptions = {
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#modal_dayplan').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.tourplanday = JSON.parse( '{"id":0,"master_id":0,"work_date":"2000-01-01","session1_worktype":"FieldWork","session2_worktype":"FieldWork","has_jointworker":false,"fieldpeople_id":0,"jointworker_id":0,"remarks":"","jointworker":{},"areas1":[],"areas2":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/tourplanday/emp/${self.$store.state.user.id}/${self.$data.dayplan_date.format('YYYY-MM-DD')}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          $('#modal_dayplan').unblock();

          console.log(JSON.stringify(resp.data));

          if(!_.isNull(resp.data)) {
            self.$data.tourplanday = resp.data;
          }

          let areas1 = [];
          self.$data.tourplanday.areas1.forEach((a)=>{
            areas1.push(a.area_id);
          });

          let areas2 = [];
          self.$data.tourplanday.areas2.forEach((a)=>{
            areas2.push(a.area_id);
          });

          $('#cmbSession1Area').val(areas1);
          $('#cmbSession2Area').val(areas2);

          $("#cmbSession1Area").multiselect("refresh");
          $("#cmbSession2Area").multiselect("refresh");

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#modal_dayplan').unblock();
      });

    },
    on_event_click(evt, element){
      // alert(evt.event.id);
      console.log(JSON.stringify(evt.event));
    },
    fetch_month_data(month, year){
      const self = this;

      const requestOptions = {
        mode:"cors",
        headers: userService.authHeader()
      };

      let date =  year + "-" + ('00' + month).slice(-2)  + "-01";

      self.$data.calendar.removeAllEvents();
      self.$data.tourplan = JSON.parse('{"id":0,"month":"2000-00-01","type":0,"status":"Active","fieldpeople_id":2,"accepted_by_id":0,"accepted_by":{},"fieldpeople":{},"list":[]}');

      $('#tourplan_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/tourplan/user/${self.$store.state.user.id}/month/${date}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          $('#tourplan_card').unblock();
          self.$data.tourplan = resp.data;

          try {

            if(Array.isArray(self.$data.tourplan.list)) {

              self.$data.tourplan.list.forEach((data) => {

                self.$data.calendar.addEvent({
                  id :data.id,
                  title: data.session1_worktype,
                  start: data.work_date
                });

                self.$data.calendar.addEvent({
                  id :data.id,
                  title: data.session2_worktype,
                  start: data.work_date
                });
              });
            }

          }catch (e) {
            alert(e);
          }
          //console.log(JSON.stringify(resp.data));
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#tourplan_card').unblock();
      });
    },
    perform_day_save(){
      const self = this;

      let $selected1 = $('#cmbSession1Area').find('option:selected');
      let $selected2 = $('#cmbSession2Area').find('option:selected');

      self.tourplanday.work_date = self.dayplan_date.format('YYYY-MM-DD');
      self.tourplanday.fieldpeople_id = self.$store.state.user.id;
      self.tourplanday.areas1 = [];
      self.tourplanday.areas2 = [];

      if(self.tourplanday.session1_worktype == 'FieldWork') {
        $selected1.each((idx, val) => {
          let localArea =  {"id": 0, "master_id": 0, "detail_id": 0, "session_id": 1, "area_id": parseInt(val.value), "work_date": self.dayplan_date.format('YYYY-MM-DD'), "name": val.name};
          self.tourplanday.areas1.push(localArea);
        });

        if(self.tourplanday.areas1.length == 0) {
          alert('Invalid Areas for First Session');
          return ;
        }
      }

      if(self.tourplanday.session2_worktype == 'FieldWork') {
        $selected2.each((idx, val) => {
          let localArea = { "id": 0, "master_id": 0, "detail_id": 0, "session_id": 2, "area_id": parseInt(val.value), "work_date": self.dayplan_date.format('YYYY-MM-DD'), "name": val.name};
          self.tourplanday.areas2.push(localArea);
        });

        if(self.tourplanday.areas2.length == 0) {
          alert('Invalid Areas for Second Session');
          return ;
        }
      }

      const requestOptions = {
        method:  'POST',
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.tourplanday)
      };

      $('#modal_dayplan').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/tourplanday`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          self.$data.calendar.addEvent({
            id : resp.data.id,
            title: resp.data.session1_worktype,
            start: resp.data.work_date
          });

          self.$data.calendar.addEvent({
            id : resp.data.id,
            title: resp.data.session2_worktype,
            start: resp.data.work_date
          });


          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000, onClose: () => {
              $('#modal_dayplan').modal('hide');
          }});
          self.$emit('tourplanday_saved', resp.data);

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#modal_dayplan').unblock();
      });


    },
  }
}
</script>

<style scoped>
</style>
